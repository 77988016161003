import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class='category topic-list-data'>"
    + container.escapeExpression((lookupProperty(helpers,"category-link")||(depth0 && lookupProperty(depth0,"category-link"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"category",{"name":"category-link","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":63}}}))
    + "</td>\n";
},"useData":true});

addRawTemplate("list/category-column", template, { core: true });
export default template;